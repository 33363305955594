<template>
  <!-- 数字通证管理 -->
  <div>
    <!-- 新增按钮 -->
    <div class="onBtn">
      <el-button type="success" style="margin-left: auto" @click="New">新增</el-button>
    </div>
    <!-- 内容区域 -->
    <div>
      <el-table :data="data" border style="width: 100%">
        <el-table-column prop="tokenName" label="数字通证名称" width="" align="center"> </el-table-column>
        <el-table-column prop="tokenEnglish" label="数字通证英文" width="" align="center"> </el-table-column>
        <el-table-column prop="tokenEnglish" label="数字通证编号" width="" align="center"> </el-table-column>
        <el-table-column prop="gross" label="数字通证总量" width="" align="center"> </el-table-column>
        <el-table-column label="涨跌幅限额" align="center">
          <template slot-scope="scope">
            <el-button @click="operate(scope.row)" :type="scope.row.old == 1 ? 'success' : 'primary'" size="mini">{{
              scope.row.old == 1 ? '30%' : '10%'
            }}</el-button>
          </template>
        </el-table-column>
        <el-table-column label="区块时间" width="" align="center">
          <template slot-scope="scope"> {{ scope.row.blocksTime }} </template>
        </el-table-column>
        <el-table-column prop="developer" label="开发者" width="" align="center"> </el-table-column>
        <el-table-column prop="issuingDate" label="发行时间" width="" align="center"> </el-table-column>
        <el-table-column prop="coreAlgorithm" label="核心算法" width="" align="center"> </el-table-column>
        <el-table-column prop="price" label="上线价格" width="" align="center"> </el-table-column>
        <el-table-column prop="onlineTime" label="上线时间" width="" align="center"> </el-table-column>
        <el-table-column prop="predictOnlineTime" label="预上线时间" width="" align="center"> </el-table-column>
        <el-table-column prop="" label="状态" align="center">
          <template slot-scope="scope">
            {{ !scope.row.status ? '上线' : '下线' }}
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center" width="150">
          <template slot-scope="scope">
            <div class="btn">
              <div @click="onToView(scope.row.id)">查看</div>
              <div @click="editor(scope.row.id)">编辑</div>
              <div @click="onOffline(scope.$index, scope.row.id, scope.row.status)">
                {{ !scope.row.status ? '下线' : '上线' }}
              </div>
              <div @click="openWhite(1, scope.row.id)">查看交易白名单</div>
              <div @click="openWhite(2, scope.row.id)">查看通证白名单</div>
              <div @click="openOption(scope.row.id)">查看通证配置</div>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <el-dialog title="白名单" center :visible.sync="white">
      <div style="display: flex; flex-direction: column; align-items: center">
        <el-table :data="whiteList" style="width: 100%">
          <el-table-column prop="id" label="ID" width="180"> </el-table-column>
          <el-table-column prop="tel" label="账户" width="180"> </el-table-column>
          <el-table-column label="操作" align="center">
            <template v-slot="scope">
              <el-button type="text" @click="deleteWhite(scope.row.id)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-button type="primary" @click="addWhite" style="margin-top: 1.25rem">添加白名单</el-button>
      </div>
    </el-dialog>

    <el-dialog title="通证配置" center :visible.sync="optionDialog" @close="optionValue={}">
      <div style="display: flex; flex-direction: column; align-items: center">
        <el-input placeholder="1.只能买2.只能卖 3.可以买也可以卖 4.不能买也不能卖" v-model="optionValue.firmType" style="margin-bottom: 10px">
          <template slot="prepend">企业白名单通证买卖类型</template>
        </el-input>
        <el-input placeholder="请输入数值" v-model="optionValue.tokenMax" style="margin-bottom: 10px">
          <template slot="prepend">转出最大值</template>
        </el-input>
        <el-input placeholder="请输入数值" v-model="optionValue.tokenMin" style="margin-bottom: 10px">
          <template slot="prepend">转出最小值</template>
        </el-input>
        <el-input placeholder="请输入数值" v-model="optionValue.transferMax" style="margin-bottom: 10px">
          <template slot="prepend">互转最大值</template>
        </el-input>
        <el-input placeholder="请输入数值" v-model="optionValue.transferMin" style="margin-bottom: 10px">
          <template slot="prepend">互转最小值</template>
        </el-input>
        <el-input placeholder="1.只能买2.只能卖 3.可以买也可以卖 4.不能买也不能卖" v-model="optionValue.userType" style="margin-bottom: 10px">
          <template slot="prepend">用户买卖类型</template>
        </el-input>
      </div>
      <el-button type="primary" :disabled="addOptionType" @click="addOption" style="margin-top: 1.25rem">添加配置</el-button>
      <el-button type="primary" @click="editOption" style="margin-top: 1.25rem">修改白名单</el-button>
    </el-dialog>

    <!-- 分页 -->
    <div class="pagination">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[10, 15, 20, 30]"
        :page-size="pageNumber"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      total: null,
      currentPage: 1,
      pageNumber: 10,
      data: null,
      // 白名单
      white: false,
      whiteList: [],
      nowId: null,
      typenum: '',
      optionDialog: false,
      addOptionType: true,
      optionValue: {},
      optionID: null
    }
  },
  created() {
    // 请求所有通证
    this.setPassCard()
  },
  methods: {
    /**
     * 打开白名单
     * @param id
     */
    openWhite(type, id) {
      this.typenum = type
      console.log(type)
      if (type == 1) {
        this.axios
          .get('/admin/firm/getWhiteList', {
            firmId: id
          })
          .then(res => {
            this.nowId = id
            this.white = true
            this.whiteList = res.data.data
          })
      } else {
        this.axios
          .get('/admin/firm/getTokenWhiteList', {
            firmId: id
          })
          .then(res => {
            this.nowId = id
            this.white = true
            this.whiteList = res.data.data
          })
      }
    },
    /**
     * 添加白名单
     */
    addWhite() {
      console.log(this.typenum)
      const that = this
      this.$prompt('请输入白名单账户', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      })
        .then(({ value }) => {
          if (that.typenum == 1) {
            this.axios
              .get('/admin/firm/addWhite', {
                firmId: this.nowId,
                tel: value
              })
              .then(res => {
                if (res.data.code != 200) {
                  that.$message({
                    type: 'error',
                    message: res.data.data
                  })
                } else {
                  this.openWhite(1, this.nowId)
                  that.$message({
                    type: 'success',
                    message: '添加成功'
                  })
                }
              })
          } else {
            this.axios
              .get('/admin/firm/addTokenWhite', {
                firmId: this.nowId,
                tel: value
              })
              .then(res => {
                if (res.data.code != 200) {
                  this.$message({
                    type: 'error',
                    message: res.data.data
                  })
                } else {
                  this.openWhite(2, this.nowId)
                  that.$message({
                    type: 'success',
                    message: '添加成功'
                  })
                }
              })
          }
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '取消输入'
          })
        })
    },
    /**
     * 删除白名单
     */
    deleteWhite(id) {
      console.log(this.typenum)
      const that = this
      this.$confirm('此操作将删除该白名单, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          if (that.typenum == 1) {
            this.axios
              .get('/admin/firm/deleteWhite', {
                id: id
              })
              .then(res => {
                this.openWhite(1, this.nowId)
                this.$message({
                  type: 'success',
                  message: '删除成功!'
                })
              })
          } else {
            this.axios
              .get('/admin/firm/deleteTokenWhite', {
                id: id
              })
              .then(res => {
                this.openWhite(2, this.nowId)
                this.$message({
                  type: 'success',
                  message: '删除成功!'
                })
              })
          }
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    // 修改涨跌幅限额
    operate(row) {
      let { id, old } = row
      if (old == 1) {
        this.$confirm('确定修改涨跌幅限额为10%?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
          .then(() => {
            this.axios
              .get('/admin/certificate/updateRate', {
                id
              })
              .then(res => {
                if (res.data.code == 200) {
                  this.$message.success(res.data.mesg)
                  this.setPassCard()
                } else {
                  this.$message.error(res.data.mesg)
                }
              })
          })
          .catch(() => {})
      }
    },

    // 点击新增
    New() {
      // alert("新增");
      this.$router.push('addOredit')
    },
    // 分页
    handleSizeChange(val) {
      this.pageNumber = val
      this.axios
        .get('/admin/certificate/findAll', {
          pageNumber: this.currentPage,
          pageSize: this.pageNumber
        })
        .then(res => {
          console.log(res)
          this.data = res.data.data.records
        })
    },
    handleCurrentChange(val) {
      this.currentPage = val
      this.axios
        .get('/admin/certificate/findAll', {
          currentPage: this.currentPage,
          pageSize: this.pageNumber
        })
        .then(res => {
          console.log(res)
          this.data = res.data.data.records
        })
    },

    // 查看数字通证
    onToView(id) {
      // 跳转新页面
      this.$router.push({ path: 'checkCertificateManagement', query: { id } })
    },
    // 点击跳往编辑
    editor(id) {
      this.$router.push({ path: 'editCardManagement', query: { id } })
    },

    onOffline(index, id, status, event) {
      console.log(id)
      if (status) {
        this.axios
          .get('/admin/certificate/updateStatus', {
            id,
            status: 0
          })
          .then(res => {
            console.log(res)
            if (res.status == 200) {
              this.data[index].status = 0
              this.$message({
                message: res.data.mesg,
                type: 'success'
              })
            } else {
              this.$message({
                message: res.data.mesg,
                type: 'error'
              })
            }
          })
      } else {
        // 否则 为假就是上线状态 点击则下线
        this.axios
          .get('/admin/certificate/updateStatus', {
            id,
            status: 1
          })
          .then(res => {
            console.log(res)
            if (res.status == 200) {
              this.data[index].status = 1
              this.$message({
                message: res.data.mesg,
                type: 'success'
              })
            } else {
              this.$message({
                message: res.data.mesg,
                type: 'error'
              })
            }
          })
      }
    },
    // 请求所有数字通证
    setPassCard() {
      this.axios.get('/admin/certificate/findAll', {}).then(res => {
        if (res.data.code == 200) {
          this.data = res.data.data.records
          this.total = res.data.data.total
          this.currentPage = res.data.data.current
          this.pageNumber = res.data.data.size
        }
      })
    },
    // 查看通证配置
    openOption(id){
      this.optionID = id
      this.axios.get('/admin/tokenRule/findByCertificateId', {
        id: id
      }).then(res => {
        if (res.data.data) {
          this.addOptionType = true
          this.optionValue = res.data.data
          this.optionDialog = true
        }else{
          this.addOptionType = false
          this.optionDialog = true
        }
      })
    },
    // 新增通证配置
    addOption(){
      this.axios.post('/admin/tokenRule/addTokenRule', {
        certificateId: this.optionID,
        firmType: this.optionValue.firmType,
        tokenMax: this.optionValue.tokenMax,
        tokenMin: this.optionValue.tokenMin,
        transferMax: this.optionValue.transferMax,
        transferMin : this.optionValue.transferMin,
        userType: this.optionValue.userType
      }).then(res => {
        if(res.data.code == 200){
          this.$message.success('添加成功')
        }else{
          this.$message.error(res.data.data)
        }
      })
    },
    // 修改配置
    editOption(){
      this.axios.put('/admin/tokenRule/updateTokenRule', {
        id: this.optionValue.id,
        certificateId: this.optionID,
        firmType: this.optionValue.firmType,
        tokenMax: this.optionValue.tokenMax,
        tokenMin: this.optionValue.tokenMin,
        transferMax: this.optionValue.transferMax,
        transferMin : this.optionValue.transferMin,
        userType: this.optionValue.userType
      }).then(res => {
        if(res.code == 200){
          this.$message.success('修改成功')
        }else{
          this.$message.error(res.data.data)
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.onBtn {
  display: flex;
  align-items: center;
  padding: 20px;
}
.btn {
  display: flex;
  justify-content: center;
  div {
    margin: 0 3px;
    font-size: 12px;
    color: #409eff;
  }
  div:hover {
    cursor: pointer;
  }
}
.pagination {
  display: flex;
  justify-content: center;
  margin-top: 50px;
}
</style>
